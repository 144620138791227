import React, { useState } from 'react'
import { v4 as uuid } from 'uuid';
import { Bean } from '../../bean'
import * as styles from './index.module.css'

interface Props extends Bean {
  autoFocus?: boolean
  value: string
  type?: 'text' | 'tel' | 'email'
  placeholder?: string
  name?: string
  validated?: boolean
  onChange: (value: string) => void
}

const Input: React.FC<Props> = ({
  className = '',
  style = {},
  children,
  autoFocus = false,
  value,
  type = 'text',
  placeholder = '',
  name = '',
  validated = true,
  onChange,
}) => {
  const [touched, setTouched] = useState(false)
  // TODO: во время ререндера ид обновляется
  const id = uuid().slice(-12)
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }
  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.wrapper}>
        <label className={styles.label} htmlFor={id}>{children}</label>
      </div>
      <input
        className={`${styles.input} ${!validated && touched && styles.error} ${className}`}
        onChange={changeHandler}
        onBlur={() => setTouched(true)}
        {...{
          id,
          autoFocus,
          value,
          type,
          placeholder,
          name,
          style,
        }}
      />
    </div>
  )
}

export default Input
