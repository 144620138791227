import React from 'react'
import { Bean } from '../../bean'
import * as styles from './assets/css/index.module.css'
import RemoveIcon from './assets/images/close.svg'

interface Props extends Bean {
  onRemove?: () => void
}

const Chip: React.FC<Props> = ({
  className = '',
  style = {},
  children,
  onRemove,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.grid}>
          <div className={styles.content}>{children}</div>
          <div className={styles.close} onClick={() => onRemove()}>
            <RemoveIcon />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chip