import * as React from 'react'
import { Link } from '..'
import { Link as ILink } from '../link/types'
import * as styles from './index.module.css'

interface Props extends Omit<ILink, 'href'> {
  el?: 'button' | 'a'
  href?: string
  disabled?: boolean
  onClick?: () => void
}

const Button: React.FC<Props> = ({
  el = 'button',
  children,
  className = '',
  style,
  href,
  target,
  disabled = false,
  onClick
}) => (
  (el === 'button')
    ? <button className={`${styles.root} ${className}`} {...{style, disabled, onClick}}>{children}</button>
    : <Link className={`${styles.root} ${className}`} {...{href, style, disabled, onClick, target}}>{children}</Link>
)

export default Button