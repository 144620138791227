import React from 'react'
import { Bean } from '../../bean'
import * as styles from './index.module.css'

interface Props extends Bean {
  checked: boolean
  onClick: () => void
}

const Burger: React.FC<Props> = ({
  className = '',
  style = {},
  children,
  checked,
  onClick,
}) => (
  <div
    className={`${styles.root} ${className} ${checked && styles.active}`}
    aria-hidden="true"
    {...{ onClick, style }}
  >
    <button
      type="button"
      className={styles.burger}
    >
      <span />
      <span />
      <span />
    </button>
  </div>
)

export default Burger
