import React from 'react'
import { Bean } from '../../bean'
import * as styles from './index.module.css'

interface Props extends Bean {
  to?: string
}

const Social: React.FC<Props> = ({
  className = '',
  style = {},
  children,
  to,
}) => {
  return (
    <a href={to} target="_blank" className={`${styles.root} ${className}`} style={style}>
      <div className={styles.wrapper}>
        <figure className={styles.shadow}></figure>
        <div className={styles.social}>
          {children}
        </div>

      </div>
    </a>
  )
}

export default Social