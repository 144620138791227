import * as React from 'react'
import * as styles from './index.module.css'
import { Link as Props } from './types'

const Link: React.FC<Props> = ({
  children,
  className = '',
  style = {},
  href,
  target = '_self'
}) => (
  <a href={href} className={`${styles.root} ${className}`} style={style} target={target}>
    {children}
  </a>
)

export default Link