import * as React from 'react'
import { Bean } from '../../bean'
import * as styles from './index.module.css'

interface Props extends Bean {
  id?: string
}

const Container: React.FC<Props> = ({
  className = '',
  style = {},
  children,
  id
}) => (
  <div className={`${styles.root} ${className}`} {...{id, style}} >
    {children}
  </div>
)

export default Container