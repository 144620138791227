import * as React from 'react'
import * as styles from './index.module.css'

interface Props {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const Paragraph: React.FC<Props> = ({
  children,
  className,
  style
}) => (
  <p className={`${styles.root} ${className}`} style={style}>{children}</p>
)

export default Paragraph