import * as React from 'react'
import * as styles from './index.module.css'

export interface Props {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  el?: 'h2' | 'h3'
}

const Heading: React.FC<Props> = ({
  children,
  className = '',
  style,
  el = 'h2'
}) => {
  const heading = React.createElement(
    el, 
    {
      className: `${styles.root} ${styles[el]} ${className}`,
      style
    },
    children
  )
  return heading
}

export default Heading